import {
  selectors,
  LISTINGS_CHANGED_EVENT,//Listings were changed during filtering or infinite loader
  UPDATE_LISTINGS_EVENT,//Need to update listings after map changes or filtering
  LOAD_MORE_LISTINGS_EVENT,//Need to load more listings with infinite loader
  CHECK_LISTING_LOADER_EVENT,//Check do we have some listings to load for current map and filters params
  LISTINGS_FILTERED_BY_MAP_EVENT,//Map view bounds were changed
  COLLAPSED_TAGS_UPDATED_EVENT //Filter html was changed
} from './utils/constants';
import GalleryFilters from './filters';
import ListingsLoader from './listings_loader';
import GalleryMap from './map';
import {inIframe} from '../../utils/iframe_helper';
import GalleryUtils from './utils/gallery_utils';
import ListingsUtils from './utils/listings_utils';
import MapUtils from './utils/map_utils';
import PromoBanners from './promo_banners';
import Listings from './listings';
import MapResizeHandler from './map_resize_handler';

class MapSearch {
  #galleryWrapper;//General container for gallery
  #listingsWrapper;//Container for listings
  #mapWrapper;//Container for map
  #galleryMapInstance;//Instance of current map provider
  #lisitingsInstance;//Instance of class that activate/deactivate all js inside listing tile
  #loadedMarkers = [];//Markers for current filter form parameters
  #askedListings = [];//Listings that we already asked for infinite loader from server

  connectListings() {
    this.#lisitingsInstance.bindEvents();
  }

  disconnectListings() {
    this.#lisitingsInstance.unbindEvents();
  }

  updateListings(data, page, keepMapView) {
    const wrapper = this.#listingsWrapper;
    let listingsHtml = Object.values(data.listing_tiles).reduce(function(previousValue, currentValue) {
      return previousValue + currentValue;
    }, '');
    this.disconnectListings();
    if (page > 1) {
      wrapper.insertAdjacentHTML('beforeend', listingsHtml);
      ListingsUtils.removeListingsFetchingStatus(this.#listingsWrapper);
    } else {
      wrapper.innerHTML = listingsHtml;
      ListingsUtils.removeListingsFetchingStatus(this.#listingsWrapper);
      wrapper.dispatchEvent(new CustomEvent(LISTINGS_CHANGED_EVENT, {detail: {...data, ...{keepMapView}}}));
    }
    this.connectListings();

    if (keepMapView) {
      wrapper.dispatchEvent(new CustomEvent(CHECK_LISTING_LOADER_EVENT, {detail: {...data, ...{markers: this.#loadedMarkers}}}));
    }
    document.dispatchEvent(new CustomEvent('contentModified'));
  }

  updateFilters(data) {
    document.querySelector(selectors.COLLAPSED_FILTERS).innerHTML = data.filters_html;
    this.#galleryWrapper.dispatchEvent(new CustomEvent(COLLAPSED_TAGS_UPDATED_EVENT));
    GalleryUtils.setFiltersResultCountHtml(this.getViewPortListings().length);
    const addressFilterValue = document.querySelector('.js-address-filter-value');
    document.querySelectorAll('.js-filter-result-location').forEach(el => {
      el.innerHTML =
        addressFilterValue ? `in ${addressFilterValue.textContent}` : '';
    });
  }

  //Get last loaded and ordered markers list and filter by map bounds
  getViewPortListings() {
    const markers = this.#loadedMarkers;
    const bounds = this.#galleryMapInstance.getMapBounds();
    let listingsWithinBounds = [];
    markers.forEach((marker) => {
      const position = marker.lat_long;
      if (MapUtils.mapIsNotIncluded() || bounds.containsLatLng(position[0], position[1])) {
        listingsWithinBounds.push(marker.options.listing_uid);
      }
    });
    return listingsWithinBounds;
  }

  // 1. Get last loaded and ordered markers list filtered by map bounds
  // 2. Remove from this list all loaded from server listings
  // 3. After that fetch first 'per page' listings from the rest list
  getPaginatedViewPortListings() {
    const perPage = +this.#listingsWrapper.dataset.perPage;
    const alreadyLoadedListings = [...ListingsUtils.getAllVisibleListings()].map(l => l.id);
    const viewportListingsToLoad = this.getViewPortListings().filter(l => !alreadyLoadedListings.includes(`uid_${l}`) && !this.#askedListings.includes(l));
    return viewportListingsToLoad.slice(0, perPage);
  }

  getUrlWithParamsForFetch(url, filterForm, keepMapView) {
    const form = document.querySelector(`${selectors.EXPANDED_DESKTOP_FILTERS} form`);
    let params = new URLSearchParams([...(new FormData(form)).entries()]);
    if (keepMapView) {
      const bounds = this.#galleryMapInstance.getMapBounds();
      params.append('bounds[north_east][lat]', bounds.getTopLeft().lat);
      params.append('bounds[north_east][long]', bounds.getBottomRight().lng);
      params.append('bounds[south_west][lat]', bounds.getBottomRight().lat);
      params.append('bounds[south_west][long]', bounds.getTopLeft().lng );
    }
    params.append('page', 1);
    url.search = params.toString();
    return url;
  }

  getUrlWithParamsForLoader(url, paginatedListings) {
    let params = new URLSearchParams();
    params.append('listings', paginatedListings);
    url.search = params.toString();
    return url;
  }

  //Get next portion of listings for infinite loader
  loadMoreListings(listingUid = null) {
    let url = new URL(`${this.#listingsWrapper.dataset.url}/${this.#listingsWrapper.dataset.listingTilesPath}`,
      window.location.origin);
    let page = this.#listingsWrapper.dataset.page;
    page++;
    const paginatedListings = this.getPaginatedViewPortListings();
    if (paginatedListings.length > 0 && !ListingsUtils.isListingsFetchingStatus(this.#listingsWrapper)) {
      this.#askedListings = this.#askedListings.concat(paginatedListings);
      ListingsUtils.increasePageNumber();
      MapUtils.disableMap();
      GalleryUtils.disableFilters();
      fetch(this.getUrlWithParamsForLoader(url, paginatedListings), {headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }}).then(response => response.json()).then((data) => {
        if (Object.values(data).length > 0) {
          ListingsUtils.setEmptyListingsInfoHtml('');
        }
        this.updateListings({listing_tiles: data}, page, true);
        MapUtils.enableMap();
        GalleryUtils.enableFilters();
        if (listingUid) {
          const listing = document.querySelector(`#uid_${listingUid}`);
          if (listing) {
            listing.scrollIntoView({behavior: 'smooth', block: 'end'});
            listing.classList.add('selected');
          } else {
            document.querySelector('.js-listings').dispatchEvent(new CustomEvent('listings:load-more', {detail: {listing: listingUid}}));
          }
        }
      }).catch((err) => {
        MapUtils.enableMap();
        GalleryUtils.enableFilters();
        console.log(err);
      });
    } else {
      ListingsLoader.disableLazyLoad(this.#listingsWrapper);
    }
  }

  //Fetch listings from the first page for current map and filters parameters
  fetchListings(filterForm, keepMapView) {
    if(!ListingsUtils.isListingsFetchingStatus(this.#listingsWrapper)) {
      ListingsUtils.addListingsFetchingStatus(this.#listingsWrapper);
      let url = new URL(window.location.href);
      GalleryUtils.disableFilters();
      MapUtils.disableMap();
      ListingsUtils.setPage(1);
      fetch(this.getUrlWithParamsForFetch(url, filterForm, keepMapView), {headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }}).then(response => response.json()).then((data) => {
        MapUtils.enableMap();
        GalleryUtils.enableFilters();
        if (!keepMapView) {
          this.#loadedMarkers = data.markers;
        }
        this.#askedListings = Object.keys(data.listing_tiles);
        ListingsUtils.updateEmptyListingInfo(data);
        this.updateListings(data, 1, keepMapView);
        this.updateFilters(data);
        ListingsLoader.enableLazyLoad(this.#listingsWrapper);
      }).catch((err) => {
        MapUtils.enableMap();
        GalleryUtils.enableFilters();
        ListingsUtils.removeListingsFetchingStatus(this.#listingsWrapper);
        console.log(err);
      });
    }
  }

  // Check if current listings in the map bounds contain new listings. Reload whole list in such cases.
  needToReloadMapAfterChange() {
    let listingsWithinMapBounds = this.#mapWrapper.dataset.listingsWithinBounds;
    if (listingsWithinMapBounds === null || listingsWithinMapBounds === 'undefined') return false;
    listingsWithinMapBounds = this.#mapWrapper.dataset.listingsWithinBounds.split(',');
    let prevListingsWithinMapBounds = this.#mapWrapper.dataset.prevListingsWithinBounds;
    if (prevListingsWithinMapBounds === null || prevListingsWithinMapBounds === 'undefined') return false;
    prevListingsWithinMapBounds = prevListingsWithinMapBounds.split(',');
    return listingsWithinMapBounds.
      filter(l => !prevListingsWithinMapBounds.includes(l) && !document.getElementById(`uid_${l}`)).length > 0;
  }

  //Show/hide listings for new map bounds, reload whole list if new markers appear in the viewport
  filterMarkersOnMap() {
    let listingsWithinBounds = this.getViewPortListings();
    // Remember bounds and listings within bounds, and trigger event to signal that bounds were changed
    const oldListingsWithBounds = this.#mapWrapper.dataset.listingsWithinBounds;

    this.#mapWrapper.setAttribute('data-prev-listings-within-bounds', oldListingsWithBounds);
    this.#mapWrapper.setAttribute('data-listings-within-bounds', listingsWithinBounds);

    document.querySelectorAll(selectors.LISTING).forEach(l => {
      if (listingsWithinBounds.includes(l.id.replace('uid_', ''))) {
        //Show listing if it is in map viewport
        l.classList.remove(selectors.HIDDEN_CLASS);
      } else {
        l.classList.add(selectors.HIDDEN_CLASS);
      }
    });

    const needToReload = this.needToReloadMapAfterChange();

    if (!needToReload) {
      this.#listingsWrapper.dispatchEvent(new CustomEvent(CHECK_LISTING_LOADER_EVENT, {detail: {markers: this.#loadedMarkers}}));
    }

    //Map changed during initial markers setup, we already have loaded all needed listings
    if (!this.#listingsWrapper.dataset.initialFetch || JSON.parse(this.#listingsWrapper.dataset.initialFetch)) {
      this.#listingsWrapper.setAttribute('data-initial-fetch', false);
      return;
    }

    if (needToReload) {
      this.fetchListings(null, true);
    }
  }

  initPullListings() {
    const listings = this.#listingsWrapper;
    const that = this;

    document.querySelectorAll('.js-listings, .js-listings-wrapper').forEach(elem => {
      elem.addEventListener('touchstart', (event) => {
        if (event.targetTouches.length === 1 && window.scrollY === 0) {
          const start = event.targetTouches[0].pageY;
          const moveHandler = function (event) {
            const touch = event.targetTouches[0];
            if (start < touch.pageY) {
              event.preventDefault();
            }
            listings.style.top = (touch.pageY - start) + 'px';
          };
          const touchendHandler = function (e) {
            listings.style.top = 0;
            if (start < e.changedTouches[0].clientY && !MapUtils.mapIsNotIncluded()) {
              MapUtils.openMobileMap();
              that.#galleryMapInstance.resizeMapViewport();
            }
            document.removeEventListener('touchmove', moveHandler);
            document.removeEventListener('touchend', touchendHandler);
          };
          document.addEventListener('touchmove', moveHandler, {passive: false});
          document.addEventListener('touchend', touchendHandler, {passive: false});
        }
      }, {passive: false});
    });
  }

  bindListingsEvents() {
    this.#listingsWrapper.addEventListener(UPDATE_LISTINGS_EVENT, (e) => {
      //Set this attribute to prevent markers changed event after first page load or filter form changed
      this.#listingsWrapper.setAttribute('data-initial-fetch', true);
      this.fetchListings(e.detail ? e.detail.filterForm : null, false);
    });
    this.#listingsWrapper.addEventListener(LISTINGS_CHANGED_EVENT, () => {
      //Reinitialize event listeners for the new listings list
      this.disconnectListings();
      this.connectListings();
    });
    this.#listingsWrapper.addEventListener(LOAD_MORE_LISTINGS_EVENT, (e) => {
      this.loadMoreListings(e.detail ? e.detail.listing : null);
    });
  }

  bindMapEvents() {
    window.addEventListener('resize', () => {
      MapUtils.setMapWrapperHeight();
      this.#galleryMapInstance.resizeMapViewport();
      GalleryUtils.setElementsPositions();
    });

    window.addEventListener('orientationchange', () => {
      GalleryUtils.setElementsPositions();
    });

    this.#mapWrapper.addEventListener(LISTINGS_FILTERED_BY_MAP_EVENT, () => {
      this.filterMarkersOnMap();
      GalleryUtils.setFiltersResultCountHtml(this.getViewPortListings().length);
    });

    document.addEventListener('gallery-map-v2:init', () => {
      this.#galleryMapInstance.init(document.querySelector(selectors.MAP), this.#listingsWrapper, this.#galleryWrapper);
    });
  }

  bindSpecialEvents() {
    const special = document.querySelector('.js-close-special-announcement');
    if (special) {
      special.addEventListener('click', () => {
        special.closest('.special-announcement-wrapper').style.display = 'none';
        GalleryUtils.setElementsPositions();
        MapUtils.setMapWrapperHeight();
        this.#galleryMapInstance.resizeMapViewport();
      });
    }
  }

  bindEvents() {
    this.initPullListings();
    this.connectListings();
    this.bindListingsEvents();
    this.bindMapEvents();
    this.bindSpecialEvents();
  }

  initIframeGallery() {
    this.#galleryWrapper.classList.add(selectors.IN_IFRAME_CLASS);
    document.querySelector('.gallery-announcement-container').classList.add(selectors.IN_IFRAME_CLASS);
    document.querySelector('#gallery-wrapper').classList.add(selectors.IN_IFRAME_CLASS);
    // Make scrollbar visible on hover for Chrome
    let styles = `
      html {
        width: 100vw;
        overflow-x: hidden;
      }
      html:hover {
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .5);
          box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
      }      
    `;
    let styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerHTML = styles;
    document.head.appendChild(styleSheet);
  }

  showUnsibscribeAlert() {
    document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
      content: '<div class="sm-modal-content"><p class="gallery-modal-content">Thanks, we won’t email you any more.</p><div class="listing-controls"><a href="#" class="listing-button js-modal-close-trigger">Ok</a></div></div>'
    }}));
  }

  init(gallery, listings, map) {
    this.#galleryWrapper = gallery;
    this.#listingsWrapper = listings;
    this.#mapWrapper = map;
    this.#loadedMarkers = JSON.parse(this.#mapWrapper.dataset.markers);
    // Init lisitings
    this.#lisitingsInstance = new Listings();
    this.#lisitingsInstance.init();

    this.bindEvents();
    if (inIframe()) {
      this.initIframeGallery();
    } else {
      GalleryUtils.showSpecialAnnouncement();
      GalleryUtils.showHeader();
      GalleryUtils.setElementsPositionsOnHeaderLoaded();
    }
    if (MapUtils.mapIsNotIncluded()) {
      this.#galleryWrapper.classList.add('without-map');
      document.querySelector('.js-map-wrapper').classList.add('hidden');
    } else {
      this.#galleryWrapper.classList.add(selectors.DEFAULT_OPENED_MAP_CLASS);
    }
    //Init map
    this.#galleryMapInstance = new GalleryMap().getInstance();
    MapUtils.setMapWrapperHeight();
    this.#galleryMapInstance.init(document.querySelector(selectors.MAP), this.#listingsWrapper, this.#galleryWrapper);
    if (!GalleryUtils.isMobileSizeViewport()) {
      new MapResizeHandler().init();
    }

    // Init filters
    new GalleryFilters().init(this.#galleryWrapper);
    //Init endless scrolling
    window['GalleryLoader'] = new ListingsLoader().init(this.#listingsWrapper);
    new PromoBanners().init();
    //Unsibscribe alert
    if (document.querySelector('#gallery-wrapper').classList.contains('js-show-unsubscribe-alert')) {
      this.showUnsibscribeAlert();
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const gallery = document.querySelector(selectors.GALLERY);
  if (gallery) {
    const mapSearch = new MapSearch();
    mapSearch.init(gallery, document.querySelector(selectors.LISTINGS), document.querySelector(selectors.MAP));
  }
});