/***
 *  Mapbox Custom Helpers for gallery v2.
 *  This page contains copy of the several mapbox_custom helpers for old version of the gallery.
 */

// Generate an html of custom numeric marker
export function numericMapboxMarker(number) {
  let html = '<div class="mapbox-custom-marker"><img src="/assets/mapbox-marker-base.png"><span';
  if(number > 999) {
    html += ' class="four-digits"';
  } else if(number > 99) {
    html += ' class="three-digits"';
  }
  html += '>';
  html += number;
  html += '</span></div>';
  return html;
}

// Add markers to map
export function addMarkersToMapboxMap(map, markers, housingTypeMarkers) {
  for(var i = 0; i < markers.length; i++) {
    var marker = markers[i];
    var mapboxMarker;
    if(marker.hasOwnProperty('options')) {
      mapboxMarker = L.marker(marker.lat_long, housingTypeMarkers ?
        housingTypeMarkerProperties(marker) :
        customMarkerProperties(marker));
    } else {
      mapboxMarker = L.marker(marker.lat_long);
    }
    mapboxMarker.addTo(map);
  }
}

// Build a map with markers on the page
export function drawMapboxMap(map_id, zoom, markers, housingTypeMarkers) {
  // setup a map
  var map = L.mapbox.map(map_id, 'mapbox.streets');
  // add markers
  addMarkersToMapboxMap(map, markers, housingTypeMarkers);
  // calculate and apply a zoom
  setMapboxMapInitialZoom(map, markers, zoom);
  // add control elements
  L.control.fullscreen({ position: 'topleft', title: 'Show fullscreen', forceSeparateButton: true }).addTo(map);
  return map;
}

// Build marker's property object from data-set
export function customMarkerProperties(marker) {
  return {
    icon: L.divIcon({
      className: 'mapbox-custom-marker-container',
      //iconUrl: '/assets/house.png',
      html: numericMapboxMarker(marker.options.number),
      iconSize: [35, 50]
    }),
    clickable: false,
    zIndexOffset: marker.options.zindex,
    listing_uid: marker.options.listing_uid
  };
}

export function housingTypeMarkerProperties(marker) {
  return {
    icon: L.divIcon({
      className: 'mapbox-custom-marker-container',
      html: '<div class="mapbox-marker-default"></div>',
    }),
    clickable: false,
    zIndexOffset: marker.options.zindex,
    listing_uid: marker.options.listing_uid
  };
}

export function fitNorthAmerica(map) {
  var center = [51.574339, -62.718758];
  var zoom = 2;
  map.setView(center, zoom);
}

// Re-zoom the map by custom options
export function setMapboxMapInitialZoom(map, markers, maxZoom) {
  // Without markers set a map view that mostly contains the North America
  if (markers.length === 0) {
    fitNorthAmerica(map);
    return;
  }
  // extract coordinates
  var latLongPoints = markers.map(function(marker) {
    return marker.lat_long;
  });
  // set new bounds
  var bounds = L.latLngBounds(latLongPoints).pad(0.15);
  map.fitBounds(bounds, { maxZoom: maxZoom });
}

// Collect all the markers that belong to the map
export function getAllMarkersOnMapboxMap(map) {
  var markers = [];
  for(var index in map._layers) {
    let elem = map._layers[index];
    if(elem._latlng !== undefined) {
      markers.push(elem);
    }
  }
  return markers;
}

// Remove all the markers that belong to the map
export function removeAllMarkersFromMapboxMap(map) {
  var old_markers = getAllMarkersOnMapboxMap(map);
  for(var i = 0; i < old_markers.length; i++) {
    old_markers[i].remove();
  }
}

// Hide all the listings that are absent on current map view
export function filterMarkersOnMapboxMap() {
  var map = $(this)[0];
  var bounds = map.getBounds();
  var markers = getAllMarkersOnMapboxMap(map);

  var listingsWithinBounds = [];

  for (var i = markers.length - 1; i >= 0; i--) {
    var marker = markers[i];
    var position = marker.getLatLng();
    if (bounds.contains(position)) {
      listingsWithinBounds.push(marker.options.listing_uid);
      $('#uid_' + marker.options.listing_uid).show();
    }
    else {
      $('#uid_' + marker.options.listing_uid).hide();
    }
  }

  // Remember bounds and listings within bounds, and trigger event to signal that bounds were changed
  var oldListingsWithBounds = $(map._container).data('listings-within-bounds');

  $(map._container).data('prev-listings-within-bounds', oldListingsWithBounds);
  $(map._container).data('listings-within-bounds', listingsWithinBounds);
  $(map._container).data('north-east', { lat: bounds.getNorthEast().lat, long: bounds.getNorthEast().lng });
  $(map._container).data('south-west', { lat: bounds.getSouthWest().lat, long: bounds.getSouthWest().lng });
  $(map._container).trigger('boundschange');
}
