import {selectors} from './utils/constants';
import MapUtils from './utils/map_utils';

class MapResizeHandler {
  init() {
    import('interactjs')
      .then((interact) => {
        interact.default('.js-resize-drag').resizable({
          preserveAspectRatio: false,
          edges: { left: false, right: '.map-control', bottom: false, top: false },
          listeners: {
            move(event) {
              if (!MapUtils.isMapDisabled()) {
                var target = event.target, x = (parseFloat(target.getAttribute('data-x')) || 0);
                x += event.deltaRect.left;
    
                target.style.webkitTransform = target.style.transform =
                    'translate(' + x + 'px, 0px)';
    
                target.setAttribute('data-x', x);
                document.querySelector(selectors.GALLERY).style.gridTemplateColumns = `minmax(200px, ${event.rect.width}px) 1fr`;
              }
            },
            end() {
              window.dispatchEvent(new Event('resize'));
            }
          },
          modifiers: [
            interact.default.modifiers.restrictSize({
              min: { width: 200 },
              max: { width: document.querySelector(selectors.MAP).clientWidth }
            })
          ]
        });
      });
  }
}

export default MapResizeHandler;
