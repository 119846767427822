export const selectors = {
  GALLERY: '.js-gallery',
  LISTING: '.js-listing',
  LISTINGS_WRAPPER: '.js-listings-wrapper',
  FILTER_LOADER: '.js-filter-loader',
  LOADER: '.js-endless-scroll-loader',
  FILTERS_WRAP: '.js-filters-wrap',
  FILTER_RESULTS_COUNT: '.js-filter-result-count',
  MAP_DISABLED: '.js-map-disabled',
  NO_LISTINGS: '.js-no-listings',
  COLLAPSED_FILTERS: '.js-collapsed-desktop-filters',
  EXPANDED_DESKTOP_FILTERS: '.js-expanded-desktop-filters',
  DEFAULT_OPENED_MAP_CLASS: 'default-map-mode',
  LISTINGS: '.js-listings',
  LISTING_EXPAND_ICON: '.js-listing-expand-icon',
  FILTER_FIELD: '.js-filter-field',
  COLLAPSED_FILTER: '.js-collapsed-filter',
  FULL_ADDRESS_FIELD: '.js-full-address-field',
  FILTERS_FORM: '.filters',
  MOBILE_FILTERS: '.js-mobile-filters',
  MAP: '.js-map-gallery-v2',
  MAP_DISABLED_BG: '.js-map-disabled-bg',
  FILTER_ICON: '.js-filter-icon',
  ENDLESS_SCROLL_ENABLED_CLASS: 'js-endless-scrolling-enabled',
  OPENED_MAP_CLASS: 'opened-map-mode',
  LOADING_CLASS: 'js-is-loading',
  DISABLED_CLASS: 'disabled',
  COLLAPSED_CLASS: 'collapsed',
  EXPANDED_CLASS: 'expanded',
  HIDDEN_CLASS: 'hidden',
  HIDDEN_VISIBILITY_CLASS: 'hidden-visibility',
  HIDDEN_FILTERS_CLASS: 'hidden-filters',
  TEMP_WRAP_CLASS: 'js-temp-listing-wrap',
  ANIMATED_CLASS: 'animated',
  NOT_ACTIVE_CLASS: 'not-active',
  BRANDING_COLORS: '.js-branding-colors',
  IN_IFRAME_CLASS: 'in-iframe',
  HIDDEN_MAP_CLASS: 'js-map-hidden'
};

export const FILTER_INPUT_CLEARED_EVENT = 'filter:input-cleared';
export const FILTER_FORM_SUBMIT_EVENT = 'filter:form-submit';
export const LISTINGS_CHANGED_EVENT = 'listings:changed';
export const UPDATE_LISTINGS_EVENT = 'listings:update';
export const LOAD_MORE_LISTINGS_EVENT = 'listings:load-more';
export const CHECK_LISTING_LOADER_EVENT = 'listings:check-loader';
export const MAP_CHANGED_EVENT = 'map:changed';
export const LISTINGS_FILTERED_BY_MAP_EVENT = 'listings:filtered-by-map';
export const COLLAPSED_TAGS_UPDATED_EVENT = 'filter:collapsed-tags-updated';

export const MOBILE_VIEWPORT_BREAKPOINT = 480;
export const DESKTOP_VIEWPORT_BREAKPOINT = 1400;
export const MAP_CHANGED_DELAY = 1000;
export const LISTINGS_ANIMATION_MS = 400;
export const COLLAPSE_FILTER_SCROLL_TOP = 450;
export const EXPAND_FILTER_SCROLL_TOP = 50;
export const REDUCED_MAP_TYPE = 'REDUCED';

export const PRIMARY_COLOR = '#265a88';
