import {drawMapboxMap} from './mapbox_custom_v2';
import {selectors} from '../utils/constants';

class MapboxGallery {
  #mapChangeTimeout;

  loadMap(mapContainerId, zoom, markers, credentials) {
    L.mapbox.accessToken = credentials.token;
    return drawMapboxMap(mapContainerId, zoom, markers, true);
  }

  bindMapEvents(map) {
    map.on('zoomend', this.triggerMapChanged);
    map.on('dragend', this.triggerMapChanged);
  }

  filterMarkersOnMapboxMap() {
    // TODO:: add filter by markers
  }

  triggerMapChanged() {
    clearTimeout(this.#mapChangeTimeout);
    this.#mapChangeTimeout = setTimeout(this.filterMarkersOnMapboxMap.bind(this), 1000);
  }

  showListingIconOnHover(map) {
    document.querySelectorAll(selectors.LISTING).forEach(l => {
      let coords = {lat: l.dataset.lat, lng: l.dataset.long};
      let options = {
        icon: L.divIcon({
          className: 'mapbox-custom-marker-container',
          html: '<div class="mapbox-marker-default"></div>'
        }),
        clickable: false,
        zIndexOffset: 1,
        listing_uid: 1
      };
      const marker = new L.marker(coords, options);
      l.addEventListener('mouseover', () => {
        map.addLayer(marker);
      });
      l.addEventListener('mouseleave', () => {
        map.removeLayer(marker);
      });
    });
  }

  init(mapContainer) {
    const map = this.loadMap(mapContainer.id,
      mapContainer.dataset.zoom,
      JSON.parse(mapContainer.dataset.markers),
      JSON.parse(mapContainer.dataset.mapCredentials));
    this.bindMapEvents(map);
    return map;
    //this.showListingIconOnHover(map);
  }

}

export default MapboxGallery;