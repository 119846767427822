import { selectors } from './utils/constants';
import { inIframe } from '../../utils/iframe_helper';

class Listings {
  #lightbox;

  expandListingHandler(e) {
    e.stopPropagation();
    const icon = e.currentTarget;
    const listing = icon.closest(selectors.LISTING);
    icon.querySelectorAll('img').forEach(img => img.classList.toggle(selectors.HIDDEN_CLASS));
    listing.classList.toggle(selectors.EXPANDED_CLASS);
  }
  activate(e) {
    const l = e.currentTarget.closest(selectors.LISTING);
    l.classList.remove(selectors.NOT_ACTIVE_CLASS);
  }
  deactivate(e) {
    const l = e.currentTarget.closest(selectors.LISTING);
    l.classList.add(selectors.NOT_ACTIVE_CLASS);
  }
  listingPictureClickHandler(e) {
    if (e.target.classList.contains('listing-video-icon') ||
      e.target.classList.contains('listing-pictures-icon') ||
      e.target.closest('.listing-media-info')) return;
    e.stopPropagation();
    window.top.location.href = e.currentTarget.querySelector('.listing-pictures-icon').href;
  }
  listingDetailsClickHandler(e) {
    e.stopPropagation();
    window.top.location.href = e.currentTarget.closest('.js-listing-info').querySelector('.js-view-listing-link').href;
  }
  reviewListing(e) {
    if (e.target.closest('.js-listing-info-footer') || e.target.classList.contains('js-listing-info-footer')) return;
    e.stopPropagation();
    window.top.location.href = e.currentTarget.querySelector('.js-view-listing-link').href;
  }
  unbindEvents() {
    document.querySelectorAll(selectors.LISTING_EXPAND_ICON)
      .forEach(icon => icon.removeEventListener('click', this.expandListingHandler));

    const listingPhotos = document.querySelectorAll('.js-listing-pictures');
    listingPhotos.forEach(photo => {
      photo.removeEventListener('click', this.listingPictureClickHandler);
    });

    document.querySelectorAll('.js-listing-info').forEach(l => l.removeEventListener('click', this.reviewListing));

    document.querySelectorAll(selectors.LISTING).forEach(l => l.removeEventListener('mouseenter', this.activate));
    document.querySelectorAll(selectors.LISTING).forEach(l => l.removeEventListener('mouseleave',this.deactivate));

    document.querySelectorAll('.listing-details').forEach(l => l.removeEventListener('click', this.listingDetailsClickHandler));

    if (!inIframe()) {
      this.#lightbox.destroy();
    }
  }
  bindEvents() {
    const expandIcons = document.querySelectorAll(selectors.LISTING_EXPAND_ICON);
    expandIcons.forEach(icon => {
      icon.addEventListener('click', this.expandListingHandler);
    });

    document.querySelectorAll(selectors.LISTING).forEach(l => l.addEventListener('mouseenter', this.activate));
    document.querySelectorAll(selectors.LISTING).forEach(l => l.addEventListener('mouseleave',this.deactivate));

    const listingPhotos = document.querySelectorAll('.js-listing-pictures');
    listingPhotos.forEach(photo => {
      photo.addEventListener('click', this.listingPictureClickHandler);
    });

    document.querySelectorAll('.js-listing-info').forEach(l => l.addEventListener('click', this.reviewListing));
    document.querySelectorAll('.listing-details').forEach(l => l.addEventListener('click', this.listingDetailsClickHandler));

    if (!inIframe()) {
      import('glightbox')
        .then((GLightbox) => {
          this.#lightbox = GLightbox.default({});
        });
    }
  }
  init() {
    this.bindEvents();
  }
}

export default Listings;
