import {FILTER_INPUT_CLEARED_EVENT, selectors} from './utils/constants';
import autoComplete from '@tarekraafat/autocomplete.js';
import GalleryUtils from './utils/gallery_utils';

class AddressAutocomplete {
  cleanupHiddenInputs() {
    document.querySelectorAll('[name="cities"]').forEach(el => {
      el.value = '';
    });
    document.querySelectorAll('[name="zips"]').forEach(el => {
      el.value = '';
    });
    document.querySelectorAll('[name="addresses"]').forEach(el => {
      el.value = '';
    });
  }

  getAutocompletePath() {
    const accountId = document.querySelector(selectors.GALLERY).dataset.accountUid;
    return `/${accountId}/listings/autocomplete_search`;
  }

  selectHandler(event, addrField, cleanupHiddenInputs, submitFilterFormHandler) {
    const item = event.detail.selection.value;
    if (!item.id) {
      return false;
    }
    if (item.field ===  'addresses') {
      document.querySelectorAll('[name="cities"]').forEach(el => {
        el.value = item.cities;
      });
      document.querySelectorAll('[name="zips"]').forEach(el => {
        el.value = item.zips;
      });
      document.querySelectorAll('[name="addresses"]').forEach(el => {
        el.value = item.addresses;
      });
    } else {
      cleanupHiddenInputs();
      document.querySelectorAll(`[name="${item.field}"]`).forEach(el => {
        el.value = item.id;
      });
    }

    document.querySelectorAll('[name="full_address"]').forEach(el => {
      el.value = item.value;
    });

    addrField.blur();
    GalleryUtils.toggleAddressIcons();
    submitFilterFormHandler(addrField.closest('.filters'), addrField.value);
  }

  keyboardHandler(e, addrField, autocomplete, cleanupHiddenInputs, submitFilterFormHandler, updateAllAddressesFields) {
    const keyCode = e.keyCode || e.which;

    if (keyCode === 13) {
      e.preventDefault();
      if (addrField.value) {
        autocomplete.start(addrField.value);
        return false;
      } else {
        cleanupHiddenInputs();
        updateAllAddressesFields(addrField);
        submitFilterFormHandler(addrField.closest('.filters'), addrField.value);
      }
    }
  }

  updateAllAddressesFields(addrField) {
    const fieldWrap = addrField.closest(selectors.FULL_ADDRESS_FIELD);
    document.querySelectorAll(`[name=${addrField.name}]`).forEach(el => {
      el.value = addrField.value;
    });
    document.querySelectorAll('[name="cities"]').forEach(el => {
      el.value = fieldWrap.querySelector('[name="cities"]').value;
    });
    document.querySelectorAll('[name="zips"]').forEach(el => {
      el.value = fieldWrap.querySelector('[name="zips"]').value;
    });
    document.querySelectorAll('[name="addresses"]').forEach(el => {
      el.value = fieldWrap.querySelector('[name="addresses"]').value;
    });
  }

  init({submitFilterFormHandler, onChangeHandler}) {
    const {selectHandler, keyboardHandler, cleanupHiddenInputs, updateAllAddressesFields} = this;
    const fields = document.querySelectorAll('[name="full_address"]');
    fields.forEach((addrField) => {
      const autocomplete = new autoComplete({
        selector: () => {
          return addrField;
        },
        placeHolder: addrField.dataset.placeholder,
        debounce: 300,
        cache: true,
        data: {
          src: async (query) => {
            try {
              const source = await fetch(`${this.getAutocompletePath()}?term=${query}`);
              return await source.json();
            } catch (error) {
              return error;
            }
          },
          keys: ['value']
        },
        input: {
          change: () => {
            autocomplete.close();
          }
        },
        resultItem: {
          highlight: 'autocomplete-result-highlight'
        },
        resultsList: {
          class: 'autocomplete-results-list',
          element: (list, data) => {
            if (!data.results.length) {
              const message = document.createElement('div');
              message.setAttribute('class', 'no-result');
              message.innerHTML = `<span>${addrField.dataset.noResultsMsg}</span>`;
              list.appendChild(message);
            }
          },
          noResults: true
        }
      });
      addrField.addEventListener('focus', function () {
        addrField.setAttribute('placeholder', addrField.dataset.focusPlaceholder);
        addrField.value = document.querySelector('[name="addresses"]').value ||
            document.querySelector('[name="zips"]').value ||
            document.querySelector('[name="cities"]').value ||
            addrField.value;
        autocomplete.start(addrField.value);

      });
      addrField.addEventListener('blur', function () {
        addrField.setAttribute('placeholder', addrField.dataset.placeholder);
      });
      addrField.addEventListener('keyup', function (event) {
        keyboardHandler(event, addrField, autocomplete, cleanupHiddenInputs, submitFilterFormHandler, updateAllAddressesFields);
      });
      addrField.addEventListener('selection', function (event) {
        selectHandler(event, addrField, cleanupHiddenInputs, submitFilterFormHandler);
      });
      addrField.addEventListener(FILTER_INPUT_CLEARED_EVENT, function () {
        cleanupHiddenInputs();
        updateAllAddressesFields(addrField);
        submitFilterFormHandler(addrField.closest(selectors.FILTERS_FORM), addrField.value);
        GalleryUtils.hideFullPageMobileFilters();
        onChangeHandler();
      });
      addrField.addEventListener('change', function () {
        updateAllAddressesFields(addrField);
        GalleryUtils.hideFullPageMobileFilters();
        onChangeHandler();
      });
    });
  }
}

export default AddressAutocomplete;
