import Listings from './listings';

class UpcomingShowings {
  init() {
    new Listings().init();
  }
}  

document.addEventListener('DOMContentLoaded', function() {
  const showings = document.querySelector('.js-upcoming-showings');
  if (showings) {
    new UpcomingShowings().init();
  }
});