import HereMapGallery from './map_providers/here_map_gallery';
import MapboxGallery from './map_providers/mapbox_gallery';
import {selectors} from './utils/constants';

class GalleryMap {
  getInstance() {
    const mapContainer = document.querySelector(selectors.MAP);
    if (mapContainer) {
      if (JSON.parse(mapContainer.dataset.useHereMaps)) {
        return new HereMapGallery();
      } else {
        return new MapboxGallery();
      }
    }
  }
}

export default GalleryMap;