import customSelect from 'custom-select';
import {
  selectors,
  FILTER_INPUT_CLEARED_EVENT,
  UPDATE_LISTINGS_EVENT,
  COLLAPSED_TAGS_UPDATED_EVENT,
  COLLAPSE_FILTER_SCROLL_TOP,
  EXPAND_FILTER_SCROLL_TOP
} from './utils/constants';
import AddressAutocomplete from './address_autocomplete';
import GalleryUtils from './utils/gallery_utils';

class GalleryFilters {
  #galleryWrapper;

  clearSelectFilters() {
    document.querySelectorAll(`select${selectors.FILTER_FIELD}`).forEach((s) => {
      s.customSelect.value = null;
    });
  }

  clearAddressFilter() {
    document.querySelector(`${selectors.MOBILE_FILTERS} input${selectors.FILTER_FIELD}`).value = null;
    document.querySelector(`${selectors.MOBILE_FILTERS} input${selectors.FILTER_FIELD}`)
      .dispatchEvent(new CustomEvent(FILTER_INPUT_CLEARED_EVENT));
  }

  clearAllFilters() {
    this.clearSelectFilters();
    this.clearAddressFilter();
  }

  submitFilterForm(filterForm, address) {
    document.querySelector(selectors.LISTINGS).setAttribute('data-zoom', 'initial');
    document.querySelector(selectors.LISTINGS).setAttribute('data-page', 1);
    document.querySelector(selectors.LISTINGS).dispatchEvent(new CustomEvent(UPDATE_LISTINGS_EVENT, {detail: {
      filterForm, address
    }}));
  }

  initCollapsedTags() {
    document.querySelector('.js-expand-filters').addEventListener('click', () => {
      document.querySelector(selectors.FILTERS_WRAP).classList.remove(selectors.COLLAPSED_CLASS);
      document.querySelector(selectors.COLLAPSED_FILTERS).classList.add(selectors.HIDDEN_FILTERS_CLASS);
      document.querySelector(selectors.EXPANDED_DESKTOP_FILTERS).classList.remove(selectors.HIDDEN_FILTERS_CLASS);
      window.scrollTo({behavior: 'smooth', top: 0} );
    });
    document.querySelectorAll('.js-clear-select-filter').forEach(f => {
      f.addEventListener('click', () => {
        const wrapper = f.closest(selectors.COLLAPSED_FILTER);
        const selector = `${selectors.EXPANDED_DESKTOP_FILTERS} [name="${wrapper.dataset.id}"]`;
        document.querySelectorAll(`[name="${wrapper.dataset.id}"]`).forEach((s) => {
          s.customSelect.value = null;
        });
        wrapper.remove();
        this.submitFilterForm(document.querySelector(selector).closest(selectors.FILTERS_FORM));
      });
    });

    document.querySelectorAll('.js-clear-filter').forEach(f => {
      f.addEventListener('click', () => {
        const wrapper = f.closest(selectors.COLLAPSED_FILTER);
        const selector = `${selectors.EXPANDED_DESKTOP_FILTERS} [name="${wrapper.dataset.id}"]`;
        document.querySelectorAll(`[name="${wrapper.dataset.id}"]`).forEach((s) => {
          s.value = null;
        });
        document.querySelector(selector).dispatchEvent(new CustomEvent(FILTER_INPUT_CLEARED_EVENT));
        wrapper.remove();
      });
    });
  }

  collapseFilters() {
    document.querySelector(selectors.FILTERS_WRAP).classList.add(selectors.COLLAPSED_CLASS);
    document.querySelector(selectors.COLLAPSED_FILTERS).classList.remove(selectors.HIDDEN_FILTERS_CLASS);
    document.querySelector(selectors.EXPANDED_DESKTOP_FILTERS).classList.add(selectors.HIDDEN_FILTERS_CLASS);
  }

  expandFilters() {
    document.querySelector(selectors.FILTERS_WRAP).classList.remove(selectors.COLLAPSED_CLASS);
    document.querySelector(selectors.COLLAPSED_FILTERS).classList.add(selectors.HIDDEN_FILTERS_CLASS);
    document.querySelector(selectors.EXPANDED_DESKTOP_FILTERS).classList.remove(selectors.HIDDEN_FILTERS_CLASS);
  }

  initFilters() {
    window.addEventListener('scroll', () => {
      if (!GalleryUtils.isMobileSizeViewport()) {
        if (window.scrollY > COLLAPSE_FILTER_SCROLL_TOP) {
          this.collapseFilters();
        } else if (window.scrollY < EXPAND_FILTER_SCROLL_TOP) {
          this.expandFilters();
        }
      } else {
        document.querySelector(selectors.FILTERS_WRAP).classList.remove(selectors.COLLAPSED_CLASS);
      }
    });

    document.querySelectorAll('.js-toggle-mobile-filters').forEach(f => {
      f.addEventListener('click', () => {
        GalleryUtils.toggleMobileFilters();
      });
    });

    document.querySelector('.js-back-to-filters').addEventListener('click', () => {
      this.clearAllFilters();
      GalleryUtils.hideFullPageMobileFilters();
    });

    document.querySelectorAll('.js-clear-address-filter').forEach(f => {
      f.addEventListener('click', () => {
        this.clearAddressFilter();
        GalleryUtils.toggleAddressIcons();
      });
    });
  }

  hideMobileMap() {
    this.#galleryWrapper.classList.remove(selectors.OPENED_MAP_CLASS);
    this.#galleryWrapper.classList.remove(selectors.DEFAULT_OPENED_MAP_CLASS);
  }

  initCustomSelects() {
    const cstSel = customSelect(`select${selectors.FILTER_FIELD}`, {
      containerClass: 'custom-select-field',
      panelClass: 'custom-select-dropdown'
    });

    cstSel.forEach(item => {
      item.select.addEventListener('change', () => {
        document.querySelectorAll(`[name=${item.select.name}]`).forEach(s => {
          s.customSelect.value = item.select.value;
        });
        this.collapseFilters();
        GalleryUtils.hideFullPageMobileFilters();
        this.submitFilterForm(item.select.closest(selectors.FILTERS_FORM));
      });
    });
  }

  bindEvents() {
    this.initFilters();
    this.initCollapsedTags();
    this.initCustomSelects();

    this.#galleryWrapper.addEventListener(COLLAPSED_TAGS_UPDATED_EVENT, () => {
      this.initCollapsedTags();
    });
  }

  init(galleryContainer) {
    this.#galleryWrapper = galleryContainer;
    this.bindEvents();
    const addressAutocomplete = new AddressAutocomplete();
    addressAutocomplete.init({submitFilterFormHandler: this.submitFilterForm, onChangeHandler: this.collapseFilters});
  }
}

export default GalleryFilters;
