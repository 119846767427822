import {
  MOBILE_VIEWPORT_BREAKPOINT,
  DESKTOP_VIEWPORT_BREAKPOINT,
  selectors
} from './constants';
import {inIframe} from '../../../utils/iframe_helper';

class GalleryUtils {
  //Filters helpers
  static disableFilters() {
    document.querySelectorAll(selectors.FILTER_LOADER).forEach(icon => {
      icon.classList.remove(selectors.HIDDEN_CLASS);
    });
    document.querySelectorAll(selectors.FILTER_ICON).forEach(i => i.classList.add(selectors.HIDDEN_CLASS));
    document.querySelector(selectors.FILTERS_WRAP).classList.add(selectors.DISABLED_CLASS);
  }

  static enableFilters() {
    document.querySelectorAll(selectors.FILTER_LOADER).forEach(icon => {
      icon.classList.add(selectors.HIDDEN_CLASS);
    });
    document.querySelectorAll(selectors.FILTER_ICON).forEach(i => i.classList.remove(selectors.HIDDEN_CLASS));
    document.querySelector(selectors.FILTERS_WRAP).classList.remove(selectors.DISABLED_CLASS);
  }

  static setFiltersResultCountHtml(html) {
    document.querySelectorAll(selectors.FILTER_RESULTS_COUNT).forEach(el => {
      el.innerHTML = html;
    });
  }

  static isFilteringDisabled() {
    return document.querySelector(selectors.FILTERS_WRAP).classList.contains(selectors.DISABLED_CLASS);
  }

  static hideFullPageMobileFilters() {
    if (!document.querySelector('.js-mobile-full-page-filters').classList.contains(selectors.HIDDEN_VISIBILITY_CLASS)) {
      GalleryUtils.toggleMobileFilters();
    }
  }

  static preventScrollWhileFilterOpened() {
    let body = document.querySelector('body');
    if (!document.querySelector('.js-mobile-full-page-filters').classList.contains(selectors.HIDDEN_VISIBILITY_CLASS)) {
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
    } else {
      body.style.overflow = 'auto';
      body.style.position = 'initial';
    }
  }

  static toggleMobileFilters() {
    document.querySelectorAll('.js-mobile-filters').forEach(el=>el.classList.toggle(selectors.HIDDEN_VISIBILITY_CLASS));
    if (!inIframe()) {
      GalleryUtils.preventScrollWhileFilterOpened();
    }
  }

  static toggleAddressIcons() {
    document.querySelectorAll('.js-address-icon').forEach(icon => {
      icon.classList.toggle(selectors.HIDDEN_CLASS);
    });
  }

  //General helpers
  static showSpecialAnnouncement() {
    const announcement = document.querySelector('.special-announcement-wrapper');
    if (announcement) {
      announcement.style = 'display: block;';
    }
  }

  static isMobileSizeViewport() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return vw <= MOBILE_VIEWPORT_BREAKPOINT;
  }

  static isDesktopSizeViewport() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return vw <= DESKTOP_VIEWPORT_BREAKPOINT;
  }

  static isGalleryAnimated() {
    return document.querySelector(selectors.GALLERY).classList.contains(selectors.ANIMATED_CLASS);
  }

  static showHeader() {
    const header = document.querySelector('.js-gallery-header');
    header.style.display = 'block';
  }

  static setElementsPositions() {
    if (!GalleryUtils.isMobileSizeViewport() && !inIframe()) {
      const header = document.querySelector('.js-gallery-header');
      const announcementWrapper = document.querySelector('.gallery-announcement-container');
      const galleryTopPadding = GalleryUtils.isDesktopSizeViewport() ? 0 : parseInt(window.getComputedStyle(header, null).getPropertyValue('padding-top'));
      const specialWrapHeight = announcementWrapper.clientHeight;
      header.style.top = `${specialWrapHeight - 1}px`;
      const map = document.querySelector('.js-map-wrapper');
      const shift = 7;

      if (GalleryUtils.isDesktopSizeViewport()) {
        map.style.top = `${specialWrapHeight + header.clientHeight + galleryTopPadding - shift}px`;
      } else {
        map.style.top = `${specialWrapHeight + galleryTopPadding - shift}px`;
      }
      map.style.postion = 'sticky';

      const filters = document.querySelector('.js-filters-wrap');
      filters.style.postion = 'sticky';
      filters.style.top = `${parseInt(map.style.top)}px`;
    }
  }

  static setElementsPositionsOnHeaderLoaded() {
    let img = document.querySelector('.js-gallery-header img');
    if (!img) {
      GalleryUtils.setElementsPositions();
      return;
    }

    if (img.complete) {
      GalleryUtils.setElementsPositions();
    } else {
      img.addEventListener('load', GalleryUtils.setElementsPositions);
      img.addEventListener('error', function (e) {
        console.log(e);
        GalleryUtils.setElementsPositions();
      });
    }
  }
}

export default GalleryUtils;
