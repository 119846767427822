import {selectors} from './constants';

class ListingsUtils {
  static addListingsFetchingStatus(wrap) {
    wrap.classList.add('js-listing-fetching');
  }

  static removeListingsFetchingStatus(wrap) {
    wrap.classList.remove('js-listing-fetching');
  }

  static isListingsFetchingStatus(wrap) {
    return wrap.classList.contains('js-listing-fetching');
  }

  static setEmptyListingsInfoHtml(html) {
    document.querySelector(selectors.NO_LISTINGS).innerHTML = html;
  }

  static updateMinHeightOfEmptyListings() {
    const noListings = document.querySelector(selectors.NO_LISTINGS);
    noListings.style.minHeight = '450px';
  }

  static updateEmptyListingInfo(data) {
    if (Object.values(data.listing_tiles).length === 0) {
      ListingsUtils.setEmptyListingsInfoHtml(data.empty_listings_html);
      ListingsUtils.updateMinHeightOfEmptyListings();
    } else {
      ListingsUtils.setEmptyListingsInfoHtml('');
      document.querySelector(selectors.NO_LISTINGS).style.minHeight = '0px';
    }
  }

  static getAllVisibleListings() {
    return document.querySelectorAll(`${selectors.LISTING}:not(.${selectors.HIDDEN_CLASS})`);
  }

  static getAllVisibleMarkersCount() {
    return JSON.parse(document.querySelector(selectors.MAP).dataset.listingsWithinBounds).length;
  }

  static increasePageNumber() {
    let page = document.querySelector(selectors.LISTINGS).dataset.page;
    page++;
    ListingsUtils.setPage(page);
  }

  static setPage(page) {
    document.querySelector(selectors.LISTINGS).setAttribute('data-page', page);
  }

  static getPage() {
    return document.querySelector(selectors.LISTINGS).getAttribute('data-page');
  }

}

export default ListingsUtils;
