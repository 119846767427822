import {
  LISTINGS_ANIMATION_MS,
  LISTINGS_FILTERED_BY_MAP_EVENT,
  MOBILE_VIEWPORT_BREAKPOINT,
  selectors,
  PRIMARY_COLOR
} from './constants';
import GalleryUtils from './gallery_utils';
import {inIframe} from  '../../../utils/iframe_helper';

class MapUtils {
  static createMarker(lat_long, options) {
    return new H.map.Marker(
      {
        lat: lat_long[0],
        lng: lat_long[1]
      },
      {
        icon: MapUtils.markerIconBuilder(),
        zIndex: options.zindex,
        data: { listing_uid: options.listing_uid }
      }
    );
  }

  static markerIconBuilder() {
    const color = document.querySelector(selectors.BRANDING_COLORS).dataset.buttonsColor || PRIMARY_COLOR;
    const svg = '<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px">' +
      `<circle r="6" cx="6" cy="6" style="fill:${color};" /></svg>`;
    return new H.map.Icon(svg);
  }

  static isMapShown() {
    const classes = document.querySelector(selectors.GALLERY).classList;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return (classes.contains(selectors.OPENED_MAP_CLASS) || classes.contains(selectors.DEFAULT_OPENED_MAP_CLASS) || vw > MOBILE_VIEWPORT_BREAKPOINT) && !MapUtils.mapIsNotIncluded();
  }

  static hideMobileMap() {
    const gallery = document.querySelector(selectors.GALLERY);
    gallery.classList.remove(selectors.OPENED_MAP_CLASS);
    gallery.classList.remove(selectors.DEFAULT_OPENED_MAP_CLASS);
    MapUtils.addAnimatedClassToGallery(LISTINGS_ANIMATION_MS);
  }

  static addAnimatedClassToGallery(time) {
    const gallery = document.querySelector(selectors.GALLERY);
    gallery.classList.add(selectors.ANIMATED_CLASS);
    setTimeout(() => {
      gallery.classList.remove(selectors.ANIMATED_CLASS);
    }, time);
  }

  static openMobileMap() {
    const gallery = document.querySelector(selectors.GALLERY);
    gallery.classList.add(selectors.OPENED_MAP_CLASS);
    gallery.classList.remove(selectors.DEFAULT_OPENED_MAP_CLASS);
    MapUtils.addAnimatedClassToGallery(LISTINGS_ANIMATION_MS);
    setTimeout(() => {
      document.querySelector(selectors.MAP).dispatchEvent(new CustomEvent(LISTINGS_FILTERED_BY_MAP_EVENT));
    }, LISTINGS_ANIMATION_MS);
  }

  static toggleMobileMap() {
    if (MapUtils.isMapShown()) {
      MapUtils.hideMobileMap();
    } else {
      MapUtils.openMobileMap();
    }
  }

  static setMapWrapperHeight() {
    const mapWrapper = document.querySelector('.js-map-wrapper');
    if (mapWrapper) {
      if (!GalleryUtils.isMobileSizeViewport()) {
        if (inIframe()) {
          mapWrapper.style.height = '95vh';
        } else {
          mapWrapper.style.height = `${window.innerHeight - mapWrapper.getBoundingClientRect().top - 10}px`;
        }
      } else {
        mapWrapper.style.height = 'initial';
      }
    }
  }

  static disableMap() {
    document.querySelector(selectors.MAP_DISABLED_BG).classList.remove(selectors.HIDDEN_CLASS);
  }

  static enableMap() {
    document.querySelector(selectors.MAP_DISABLED_BG).classList.add(selectors.HIDDEN_CLASS);
  }

  static isMapDisabled() {
    return !document.querySelector(selectors.MAP_DISABLED_BG).classList.contains(selectors.HIDDEN_CLASS);
  }

  static mapIsNotIncluded() {
    return document.querySelector('.js-map-wrapper').classList.contains(selectors.HIDDEN_MAP_CLASS) && inIframe();
  }
}

export default MapUtils;
