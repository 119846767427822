import {
  LISTINGS_CHANGED_EVENT,
  LISTINGS_FILTERED_BY_MAP_EVENT,
  MAP_CHANGED_DELAY,
  REDUCED_MAP_TYPE,
  PRIMARY_COLOR,
  selectors
} from '../utils/constants';
import GalleryUtils from '../utils/gallery_utils';
import MapUtils from '../utils/map_utils';

class HereMapGallery {
  #listingsWrapper;
  #mapWrapper;
  #galleryWrapper;
  #mapChangeTimeout;
  #map;
  #hoveredMarker;
  #bounds;

  constructor() {
    this.filterMarkersOnMap = this.filterMarkersOnMap.bind(this);
    this.triggerMapChanged = this.triggerMapChanged.bind(this);
  }

  triggerMapChanged(e) {
    if (!MapUtils.isMapShown() || GalleryUtils.isGalleryAnimated()) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      clearTimeout(this.#mapChangeTimeout);
      this.#mapChangeTimeout = setTimeout(this.filterMarkersOnMap, MAP_CHANGED_DELAY);
    }
  }

  refreshMarkers(updatedMarkers, keepMapView) {
    const mapMarkers = this.getMapMarkers();
    if (mapMarkers.length > 0) {
      HereInteractiveMap.removeMarkers(mapMarkers);
    }
    let newMarkers = updatedMarkers.map(m => {return MapUtils.createMarker(m.lat_long, m.options);});
    HereInteractiveMap.populateMapWithMarkers(newMarkers, keepMapView);
  }

  loadMap(mapContainerId, zoom, markers, credenials) {
    return HereInteractiveMap.initAndDisplayMap(mapContainerId,
      zoom, markers, credenials, {markerIconBuilder: MapUtils.markerIconBuilder, mapType: REDUCED_MAP_TYPE});
  }

  bindMapEvents(map) {
    map.addEventListener('mapviewchangeend', this.triggerMapChanged);

    this.#galleryWrapper.addEventListener('gallery-v2-mobile-map:close', () => {
      MapUtils.hideMobileMap();
    });

    this.#listingsWrapper.addEventListener(LISTINGS_CHANGED_EVENT, (e) => {
      if (!e.detail.keepMapView) {
        if (this.#hoveredMarker) {
          this.#map.removeObject(this.#hoveredMarker);
          this.#hoveredMarker = null;
        }
        this.refreshMarkers(e.detail.markers);
      }
    });
  }

  filterMarkersOnMap() {
    if (!this.#galleryWrapper.classList.contains(selectors.ANIMATED_CLASS) && MapUtils.isMapShown()) {
      this.#mapWrapper.dispatchEvent(new CustomEvent(LISTINGS_FILTERED_BY_MAP_EVENT));
    }
    this.#bounds = this.getMapBounds();
  }

  getMapMarkers() {
    return this.#map.getObjects().filter(o => o instanceof H.map.Marker && o.getData().hasOwnProperty('listing_uid'));
  }

  showMarkerForListing(e, map){
    //Cursor is moving over the listing
    if (this.#hoveredMarker) return;
    const l = e.target.closest(selectors.LISTING);
    if (l) {
      let coords = {lat: l.dataset.lat, lng: l.dataset.long};
      const color = document.querySelector(selectors.BRANDING_COLORS).dataset.buttonsColor || PRIMARY_COLOR;
      this.#hoveredMarker = new H.map.DomMarker(coords, {
        icon: new H.map.DomIcon(`<div style="background: ${color};border: 2px solid white;width:20px;height:20px;border-radius: 50%;padding:5px;margin-left: -17px;margin-top: -17px;">` +
          `<img src='/assets/${l.dataset.listingImg}' style='max-width:100%;background-color: transparent;'/></div>`)
      });
      map.addObject(this.#hoveredMarker);
    }
  }

  hideMarker(e, map) {
    //Cursor is moving over the listing
    if (e.relatedTarget && e.relatedTarget.closest && (e.relatedTarget.closest(selectors.LISTING) === e.target.closest(selectors.LISTING))) return;
    if (this.#hoveredMarker) {
      map.removeObject(this.#hoveredMarker);
      this.#hoveredMarker = null;
    }
  }

  showListingIconOnHover(map) {
    this.#listingsWrapper.addEventListener('mouseover', (e) => this.showMarkerForListing(e, map));
    this.#listingsWrapper.addEventListener('mouseout', (e) => this.hideMarker(e, map));
  }

  initMapControls() {
    document.querySelector('.js-mobile-map-icon').addEventListener('click', () => {
      MapUtils.toggleMobileMap();
      this.#map.getViewPort().resize();
    });
    document.querySelector('.js-listing-mobile-control').addEventListener('click', () => {
      MapUtils.hideMobileMap();
    });

    document.querySelectorAll('.js-listings, .js-listings-wrapper').forEach(elem => {
      elem.addEventListener('touchstart', (event) => {
        if (event.targetTouches.length === 1) {
          const start = event.targetTouches[0].pageY;
          const touchendHandler = function (e) {
            if (start > e.changedTouches[0].clientY) {
              if (MapUtils.isMapShown() && !MapUtils.mapIsNotIncluded()) {
                MapUtils.hideMobileMap();
              }
            }
            document.removeEventListener('touchend', touchendHandler);
          };
          document.addEventListener('touchend', touchendHandler, {passive: false});
        }
      }, {passive: false});
    });
  }

  getMapBounds() {
    return this.#map.getViewModel().getLookAtData().bounds.getBoundingBox();
  }

  resizeMapViewport() {
    this.#map.getViewPort().resize();
  }

  init(mapContainer, listingsContainer, galleryContainer) {
    this.#listingsWrapper = listingsContainer;
    this.#mapWrapper = mapContainer;
    this.#galleryWrapper = galleryContainer;
    const map = this.loadMap(mapContainer.id,
      mapContainer.dataset.zoom,
      JSON.parse(mapContainer.dataset.markers),
      JSON.parse(mapContainer.dataset.mapCredentials));
    this.bindMapEvents(map);
    this.showListingIconOnHover(map);
    this.#map = map;
    this.#bounds = this.getMapBounds();
    this.initMapControls();
    return map;
  }
}

export default HereMapGallery;
