import {inIframe} from '../../utils/iframe_helper';
import MapUtils from './utils/map_utils';
import GalleryUtils from './utils/gallery_utils';

$(function() {
  var iframeHost, iframeLoaded, contentsHeight, contentsWidth, newSizesTimeout;

  if (inIframe()) {
    $('.js-gallery-footer-wrap').remove();
    $('html').css('height', 'auto');
  }

  function sendMessageToParent(action, message) {
    processIframeHost();
    parent.postMessage(action + '::' + JSON.stringify(message), '*');
  }

  function triggerNewSizesToParent() {
    clearTimeout(newSizesTimeout);
    newSizesTimeout = setTimeout(sendNewSizesToParent, 100);
  }

  function sendNewSizesToParent() {
    var height = $('html').height();
    var width = $('html').width();
    var listings = $('.js-listings');
    var listingsRect = document.querySelector('.js-listings').getBoundingClientRect();
    console.log(listingsRect.top);

    // For mobile gallery we need to set minimum height to fit filters popup.
    // If we don't do this for empty listings list case filters will be cut.
    var filtersHeight = 0;
    if (GalleryUtils.isMobileSizeViewport()) {
      filtersHeight = document.querySelector('.js-mobile-full-page-filters').offsetHeight + 200;
    }

    if (contentsHeight !== height || contentsWidth !== width) {
      contentsHeight = height;
      contentsWidth  = width;
      sendMessageToParent('SIZING', {
        height: height,
        width: width,
        mapIncluded: !MapUtils.mapIsNotIncluded(),
        listingsBottom: Math.max((listingsRect.top + window.scrollY) + listings.outerHeight(), filtersHeight)
      });
    }
  }

  function processIframeHost() {
    if (window.location.href && iframeHost !== window.location.href) {
      iframeHost = window.location.href;
      sendIframeHostToParent();
    }
  }

  function sendIframeHostToParent() {
    sendMessageToParent('CONFIG', {type: 'iframeHost', value: iframeHost});
  }

  function bindEvents() {
    bindContentModified();
  }

  function bindContentModified() {
    // NOTE: 'contentModified' is a custom event and should be triggered manually
    $(document).on('contentModified', function() {
      sendMessageToParent('CONTENT', 'MODIFIED');
      triggerNewSizesToParent();
    });

    $(document).on('sizeChanged', function() {
      triggerNewSizesToParent();
    });
  }

  function sendIframeLoadedToParent() {
    if (!iframeLoaded) { return false; }
    sendMessageToParent('LOADING', 'LOADED');
  }

  function removeHeader() {
    // For v1
    $('.header > div').remove();
    $('.header').attr('class', 'header_iframe');
    // For v2
    const displayHeader = $('#iframe-config').length > 0 && $('#iframe-config').data('wsi-display-header');
    if (!displayHeader) {
      $('.js-gallery-header .gallery-header').remove();
      $('.js-gallery-header').hide();
    }
  }

  function handleParentMessages(e) {
    var action = null;
    var message = null;

    var splitted = e.data.split ? e.data.split('::') : [];

    if (splitted.length > 1) {
      try {
        action = splitted[0];
        message = JSON.parse(splitted[1]);
      } catch (exception) {
        console.log(e.data);
        console.log(exception);
      }

      switch (action) {
      case 'LOADING':
        if (message == 'GET') {
          sendIframeLoadedToParent();
        }
        break;
      case 'SIZING':
        if (message == 'GET') {
          sendNewSizesToParent();
          MapUtils.setMapWrapperHeight();
        }
        break;
      case 'SCROLL_OR_RESIZE':
        if (typeof(GalleryLoader) !== 'undefined') {
          GalleryLoader.induceLazyLoading(message.scrollTop, message.windowHeight);
        }
        break;
      case 'SELECTOR_CHANGE':
        $(message.selector).css(message.property, message.value);
        break;
      case 'MODIFY':
        if (message == 'REMOVE_HEADER') {
          removeHeader();
        }
        break;
      case 'INIT_MAP':
        document.dispatchEvent(new CustomEvent('gallery-map-v2:init'));
        break;
      case 'CONTENT':
        break;
      case 'CONFIG':
        break;
      default:
        console.log('Unknown message: ' + e.data);
        break;
      }
    }
  }

  window.addEventListener('message', handleParentMessages, false);

  $(document).ready(function() {
    iframeLoaded = true;
    processIframeHost();
    sendIframeLoadedToParent();
    bindEvents();
  });
});
