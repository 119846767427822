import {CHECK_LISTING_LOADER_EVENT, selectors, LOAD_MORE_LISTINGS_EVENT} from './utils/constants';
import {inIframe} from '../../utils/iframe_helper';
import ListingsUtils from './utils/listings_utils';

class ListingsLoader {
  #wrapper;
  #lastScrollTop;
  #lastWindowHeight;
  #bottomOffset = 200;

  constructor() {
    this.listingChangedHandler = this.listingChangedHandler.bind(this);
  }

  static removeLoading(wrapper) {
    wrapper.classList.remove(selectors.LOADING_CLASS);
    document.querySelector(selectors.LOADER).classList.add(selectors.HIDDEN_CLASS);
  }

  static enableLazyLoad(wrapper) {
    wrapper.classList.add(selectors.ENDLESS_SCROLL_ENABLED_CLASS);
  }

  static disableLazyLoad(wrapper) {
    ListingsLoader.removeLoading(wrapper);
    wrapper.classList.remove(selectors.ENDLESS_SCROLL_ENABLED_CLASS);
  }

  isScrollingEnabled() {
    return this.#wrapper.classList.contains(selectors.ENDLESS_SCROLL_ENABLED_CLASS);
  }

  addLoading() {
    this.#wrapper.classList.add(selectors.LOADING_CLASS);
    document.querySelector(selectors.LOADER).classList.remove(selectors.HIDDEN_CLASS);
  }

  static isLoading(wrapper) {
    return wrapper.classList.contains(selectors.LOADING_CLASS);
  }

  isScrolledToBottom(scrollTop, windowHeight) {
    return scrollTop >= this.#wrapper.clientHeight + this.#wrapper.offsetTop - windowHeight - this.#bottomOffset;
  }

  isSpaceBetweenContentAndBottom(scrollTop, windowHeight) {
    return windowHeight > this.#wrapper.clientHeight + this.#wrapper.offsetTop;
  }

  listingsListEmpty() {
    return ListingsUtils.getAllVisibleListings().length === 0;
  }

  needsToLoadMoreContent(scrollTop, windowHeight) {
    if (ListingsLoader.isLoading(this.#wrapper) || !this.isScrollingEnabled()){
      return false;
    }

    return this.isScrolledToBottom(scrollTop, windowHeight) ||
      this.isSpaceBetweenContentAndBottom(scrollTop, windowHeight);
  }

  loadMoreContent() {
    if (!ListingsLoader.isLoading(this.#wrapper)) { this.addLoading(); }
    this.#wrapper.dispatchEvent(new CustomEvent(LOAD_MORE_LISTINGS_EVENT));
  }

  induceLazyLoading(scrollTop, windowHeight) {
    // remember values
    this.#lastScrollTop = scrollTop;
    this.#lastWindowHeight = windowHeight;

    if (this.needsToLoadMoreContent(scrollTop, windowHeight)) {
      this.loadMoreContent(scrollTop, windowHeight);
    }
  }

  triggerLocalLazyLoading() {
    const scrollTop = window.scrollY;
    const windowHeight = window.outerHeight;
    this.induceLazyLoading(scrollTop, windowHeight);
  }

  listingChangedHandler(event) {
    ListingsLoader.removeLoading(this.#wrapper);
    if (ListingsUtils.getAllVisibleListings().length < event.detail.markers.length){
      this.induceLazyLoading(this.#lastScrollTop, this.#lastWindowHeight); // in case we need to load more
    } else {
      ListingsLoader.disableLazyLoad(this.#wrapper);
    }
  }

  initEvents() {
    window.addEventListener('scroll', () => {
      this.triggerLocalLazyLoading();
    });

    if (!inIframe()) {
      window.addEventListener('resize', () => {
        this.triggerLocalLazyLoading();
      });
    }

    this.#wrapper.addEventListener(CHECK_LISTING_LOADER_EVENT, this.listingChangedHandler);
  }

  init(listingsContainer) {
    this.#wrapper = listingsContainer;
    this.initEvents();
    ListingsLoader.enableLazyLoad(this.#wrapper);
    if (!inIframe()) {
      this.triggerLocalLazyLoading();
    }
    return this;
  }
}

export default ListingsLoader;
